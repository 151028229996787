import { useEffect } from 'react';
import './demo.scss'

const HomeDemo = (): JSX.Element => {
  useEffect(() => {
    // googlead.xyz
    // 印尼：https://hascaring.org/pay
    // 马来：https://hascharity.org/pay
    window.open('https://www.fofo99.co/m/register?tiktokPixelId=CSHP93RC77U53KQ7KO1G&affiliateCode=j4tikt0401', '_self')
    // window.open('https://unwfp.net/', '_self')
  })
  return (
    <>

    </>
  );
}

export default HomeDemo;